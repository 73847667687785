@import "../../style/abstracts/_all.scss";

p {
    margin: 0 0 20px;
}

ol,
ul {
    padding-left: 20px;
}

:local(.link) {
    color: #000;
    text-decoration: underline;
    cursor: pointer;
    background: none;
    &:hover {
        text-decoration: none;
    }
}

:local(button.link) {
    @include appearance(none);
    border: none;
    font-family: $default-font;
    font-size: inherit;
}

:local(.headerSection) {
    margin-bottom: 20px;
    :local(.subtitle) {
        display: block;
        margin-top: -8px;
    }
}

:local(.introText) {
    p {
        margin: 0;
    }
    ul {
        margin: 0;
        line-height: 1.6;
    }
}

:local(.paragraphGroup) {
    margin-bottom: 20px;
    p {
        margin-bottom: 0;
    }
}

:local(.ingress) {
    font-family: "Altis", sans-serif;
    font-size: 18px;
    @media only screen and (min-width: $screen-sm) {
        font-size: 22px;
    }
    p {
        margin: 4px 0;
    }
}

:local(.smallHeading) {
    font-size: 16px;
    font-weight: 700;
    line-height: 26.6px;
    margin: 0;
    padding: 0;
    @media only screen and (min-width: $screen-sm) {
        font-size: 19px;
    }
}

:local(.lowerAlphaList) {
    list-style: lower-alpha;
}

:local(.infoBox) {
    background-color: #ecf2f6;
    color: #000;
    padding: 20px 30px;
    margin: 10px 0 40px;
    p {
        margin: 0;
    }
    a {
        margin-top: 5px;
    }
}

:local(.block) {
    display: block;
}

:local(.noMarginTop) {
    margin-top: 0;
}

:local(.noMarginBottom) {
    margin-bottom: 0;
}

:local(.marginTopSmall) {
    margin-top: 24px;
}

:local(.marginTop) {
    margin-top: 50px;
}

:local(.marginBottomSmall) {
    margin-bottom: 24px;
}

:local(.marginBottom) {
    margin-bottom: 50px;
}

:local(.paddingTop) {
    padding-top: 24px;
}

:local(.paddingBottom) {
    padding-bottom: 24px;
}

fieldset {
    margin: 0;
    padding: 0;
    border: 0;
    legend {
        &:not(.normalText) {
            color: #000;
            display: block;
            font-size: 1rem;
            font-weight: bold;
            line-height: 1.2;
            margin-bottom: 2px;
            font-family: $default-font;
            overflow-wrap: break-word;
            padding: 0;
        }
        &.normalText {
            color: inherit;
            font-size: inherit;
            font-weight: inherit;
            line-height: inherit;
        }
        &.required {
            &:after {
                content: "*";
                display: inline-block;
                color: #9d2024;
                font-size: 0.8em;
                vertical-align: top;
            }
        }
    }
}
