@import "../../../style/abstracts/_all.scss";

:local(.fieldSection) {
    margin-bottom: 20px;
}

:local(.fieldList) {
    margin: 0;
    dt {
        font-weight: bold;
        font-size: 18px;
        line-height: 1.3;
    }
    dd,
    li {
        margin: 0;
        margin-bottom: 12px;
        font-size: 1.125rem;
    }
    &:local(.inlineFieldList) {
        > div {
            dt {
                display: inline-block;
                margin-right: 0.5em;
            }
            dd {
                display: inline-block;
            }
        }
    }
}

:local(.buttonRow) {
    margin: 5px 0;
    @media only screen and (min-width: $screen-sm) {
        display: flex;
    }
    &:local(.hasErrors) {
        button {
            border-color: #9d2024;
            @include box-shadow(0 0 2px 1px #9d2024);
        }
    }
}

:local(.fieldset) {
    background-color: rgba(0, 117, 127, 0.06);
    position: relative;
    padding: 16px 18px 20px;
    margin: 0 0 20px 0;
    width: 100%;
    box-shadow: none;
    border: none;
    legend {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 1em;
        padding: 0;
        position: relative;
        top: 1.5em;
    }
}

:local(.cleanList) {
    list-style: none;
    padding: 0;
    margin: 0;
}
